<template>
</template>
<script>

export default ({
  data(){
    return{
    file:'',
    }
  },
  created(){
      this.file=this.readXML('../../')
    //   let reader = new FileReader();
    //   reader.readAsText(file,'UTF-8');
    //   let t=this;
    //   reader.onload=function(evt){
    //       var fileString = evt.target.result;
    //       console.log(t.$x2js.xml2js(fileString))
    //   }
  },
  methods:{},
  beforeDestroy() {
  }
})
</script>
<style lang="scss" scoped>
</style>
